import React, { useState } from 'react'
import copy from "../constants/copy"
import {
  Alert,
  Jumbotron,
  Image,
  Button
} from 'react-bootstrap'
import RingSizeModal from "../modals/RingSizeModal"

export default function FormHeader({ formType }) {
  const jumbotronStyle = {
    background: `url(/assets/headers/header-${formType}.png) center / cover`
  }

  const [showRingSizeModal, setShowRingSizeModal] = useState(false)

  return (
    <>
      <div className="FormHeader-brandContainer">
        <Image className="FormHeader-brand" src="/assets/logoNgJoyeria.png" />
      </div>

      <Jumbotron className="Jumbotron mt-3 mb-0" style={jumbotronStyle}></Jumbotron>

      <Alert variant="ng-primary" className="FormHeader-text">
        {formType === 'quote'
          ? <div className="font-size-sm">
            Holi, bienvenidx a nuestro sistema de cotización, recuerda, entre más información nos brindes, tu cotización será más precisa.
            <br /><br />
            <strong>¿Qué datos debo poner en los detalles de mi solicitud de cotización?</strong>
            <br />
            Por favor dinos las dimensiones del producto, si es una cadena, el largo y las dimensiones del dije, si son unos aretes, las dimensiones de este, si es un anillo es muy importante saber la talla, sin este dato no te podremos hacer la cotización, si no conoces tu talla, <span className="FormHeader-link" onClick={() => setShowRingSizeModal(true)}>haz click aquí</span>, esto nos ayudará provisionalmente. Debes definir el material que quieres utilizar, para personalizados solo manejamos oro y plata. Debes adjuntar imágenes de tus referentes, ya que esto nos permitirá tener una idea más clara de tu pieza.
            <RingSizeModal showRingSizeModal={showRingSizeModal} setShowRingSizeModal={setShowRingSizeModal} />
            <br /><br />
            <h1>Importante! tiempos de cotizaciones:</h1>
            Se que este proceso te puede dar mucha ansiedad, por eso me parece importante informarte que las cotizaciones pueden tomar hasta 15 días en ser generadas debido a la cantidad de solicitudes y cuando esta se apruebe lo más normal es que se cronograme el inicio de fabricación para el mes siguiente, por eso si tu pedido es con urgencia, escribenos al whatsapp <a className="FormHeader-link" href="https://api.whatsapp.com/send?phone=573003966302" target="_blank" rel="noreferrer">3003966302</a> para recomendarte otros amigos joyeros que te pueden ayudar. <strong>TE AMIAU</strong>
          </div>
          : <>{copy.header[formType]} </>
        }
      </Alert>
    </>
  )
}
