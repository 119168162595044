const copy = {
  'type': {
    'purchase': 'Compra',
    'quote': 'Cotización',
    'exchange': 'Cambio',
    'warranty': 'Garantía',
    'maintenance': 'Mantenimiento',
    'return': 'Devolución',
    'ringSize': 'Medidas'
  },
  'header': {
    'purchase': 'LLena este formulario para realizar tu compra, debes llenar este formulario personalmente en tu dispositivo de confianza sin aceptar ayuda de personas que puedan alterar la información de consignación.',
    'quote': 'Holi, bienvenidx a nuestro sistema de cotización, recuerda que entre mas información nos brindes, tu cotización será mas prescisa, por favor dinos las dimensiones del producto, si cadena o aretas, si es un anillo es muy importante saver la talla, sin este dato no te podremos hacer la cotización, si no sabes como tomar tu talla, haz click aqui, esto nos ayudara provisionalmente, debes definir el material que quieres utilizar, para personalizadios solo manejamos oro y plata, debes adjuntar imagenes de tus referentes, ya que esto nos permitira tener una idea mas clara de tu pieza.<br>Se que te proceso te puede dar mucha ansiedad, por eso me parece importatne informarte que las cotizaciones pueden tomar hasta 15 dias en ser generadas debido a la cantidad de solicidutes y cuando esta se aprueve lo mas normal es que se cronograme el inicio de fabricación para el mes siguiente, por eso si tu pedido es con urgencia, escribenos al whatsapp 3003966302 para recomendarte otros amigos joyeros que te pueden ayudar. TE AMIAU',
    'exchange': 'Solicitud de Cambio',
    'warranty': 'Solicitud de Garantía',
    'maintenance': 'Solicitud de Mantenimiento',
    'return': 'Solicitud de Devolución',
    'ringSize': 'Medidas'
  },
  'action': {
    'purchase': 'Compra',
    'quote': 'Solicitud de Cotización',
    'exchange': 'Solicitud de Cambio',
    'warranty': 'Solicitud de Garantía',
    'maintenance': 'Solicitud de Mantenimiento',
    'return': 'Solicitud de Devolución',
    'ringSize': 'Medidas'
  },
  'DetailsCard': {
    'header': {
      'purchase': 'Información de Compra',
      'quote': 'Información de Cotización',
      'exchange': 'Información de Cambio',
      'warranty': 'Información de Garantía',
      'maintenance': 'Información de Mantenimiento',
      'return': 'Información de Devolución',
      'ringSize': 'Medidas'
    },
    'label': {
      'purchase': 'Recibo de pago',
      'quote': 'Referente',
      'exchange': 'Recibo de pago',
      'warranty': 'Evidencia',
      'maintenance': 'Estado Actual',
      'return': 'Razones',
      'ringSize': 'Medidas'
    }
  },
  'InfoCard': {
    'header': {
      'billing': [
        'Información de Facturación y Envío',
        'Información de Facturación'
      ],
      'shipping': ['Información de Envío', 'Información de Envío'],
      'quote': ['Información de Cotización'],
      'exchange': ['Información del Cliente'],
      'warranty': ['Información del Cliente'],
      'maintenance': ['Información del Cliente'],
      'return': ['Información del Cliente'],
      'ringSize': ['Información del Cliente']
    }
  },
  'ConfirmationModal': {
    'confirmationMessage': {
      'purchase': 'Gracias por tu Compra!',
      'quote': 'Haz enviado tu Solicitud de Cotización!',
      'exchange': 'Haz enviado tu Solicitud de Cambio!',
      'warranty': 'Haz enviado tu Solicitud de Garantía!',
      'maintenance': 'Haz enviado tu Solicitud de Mantenimiento!',
      'return': 'Haz enviado tu Solicitud de Devolución!',
      'ringSize': 'Haz enviado tus medidas de Anillo!'
    }
  }
}

export default copy